import React from "react";

export default function Dashboard() {

    return (
        <div className={"pt-[60px] bg-background-color"}>
            <section className={"w-full"}>
                <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Statisticile tale:</h1>
            </section>
            <section className={"w-full mt-7"}>
                <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Cursurile neterminate de tine:</h1>
            </section>
            <section className={"w-full mt-7"}>
                <h1 className={"px-3 md:px-8 text-xl md:text-4xl font-semibold"}>Rezultate de la quiz-uri:</h1>
            </section>
        </div>
    )
}