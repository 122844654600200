import React, {ReactNode, useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import DashboardAdminRouter from "../functionals/router/DashboardAdminRouter";
import {IoArrowUp} from "react-icons/io5";
import {FaBook} from "react-icons/fa";
import {FaUsersGear} from "react-icons/fa6";
import {RiLiveFill} from "react-icons/ri";
import {PiProjectorScreenFill} from "react-icons/pi";

export default function DashboardAdminActionsPage() {

    const {user} = useContext(AuthContext);
    const location = useLocation();

    const actions: { actionIcon: ReactNode, actionName: string, actionPath: string }[] = [
        {
            actionIcon: <FaBook/>,
            actionName: "Adminstreaza cursuri",
            actionPath: location.pathname + DashboardAdminRouter.courses
        },
        {
            actionIcon: <FaUsersGear/>,
            actionName: "Adminstreaza utilizatori",
            actionPath: location.pathname + DashboardAdminRouter.users
        },
        {
            actionIcon: <RiLiveFill/>,
            actionName: "Adminstreaza lives",
            actionPath: location.pathname + DashboardAdminRouter.lives
        },
        {
            actionIcon: <PiProjectorScreenFill />,
            actionName: "Adminstreaza proiecte depuse",
            actionPath: location.pathname + DashboardAdminRouter.projects
        },
    ];

    return (
        <div className={"pt-[50px]"}>
            <h1 className={"md:pt-8 px-3 md:px-8 text-xl md:text-4xl font-bold mt-8"}>Bine ai venit {user?.username}</h1>
            <h1 className={"mt-1 md:mt-4 px-3 md:px-8 text-lg md:text-2xl font-semibold"}>Acestea sunt actiunile tale</h1>
            <div className={"px-3 md:px-8 py-4 grid md:grid-cols-2 gap-4 md:gap-2"}>
                {
                    actions.map((action, i) => {
                        return <div
                            key={i}
                            className={"w-full flex justify-center items-center bg-white rounded-lg shadow-lg border border-accent-main-200"}>
                                <span
                                    className={"h-full flex items-center w-fit text-2xl rounded-l-lg text-accent-main-950 py-3 px-5 bg-accent-main-50"}>
                                    {action.actionIcon}
                                </span>
                            <div className={"flex justify-between items-center p-3 w-full bg-element-background rounded-r-lg"}>
                                <p className={"text-lg"}>{action.actionName}</p>
                                <Link to={action.actionPath}
                                      className="flex items-center justify-center w-8 h-8 bg-accent-main-100 rounded-lg">
                                    <IoArrowUp className={"text-xs text-accent-main-600"}/>
                                </Link>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
}