export default class ApplicationRoutes {
    static root = "/";
    static maintenance = "/mentenanta";
    static login = "/sign-in";
    static passwordRecoveryStart = "/password-recovery";
    static passwordRecoveryEnd = "/password-recovery/:token";
    static registerStudents = "/sign-up/students/:token";
    static registerStudentsEntry = "/sign-up/students/";
    static registerProfessors = "/sign-up/teachers";
    static registerParents = "/sign-up/parents";
    static verify = "/verify/:token";
    static dashboard = "/dashboard";
    static dashboardAdminRoot = "/dashboard/admin";
    static any = "*";
}